import React, { useContext, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { navigate } from "gatsby";

import { BookingContext } from "../../contexts/BookingContext";

import BookingRoomHeader from "../../components/booking/bookingRoomHeader";
import BookingDateTime from "../../components/booking/bookingDateTime";
import BookingRoomSummary from "../../components/booking/bookingRoomSummary";
import BookingRoomCost from "../../components/booking/bookingRoomCost";

import {
  bookingInfoWrapper,
  contactForm,
  choicesLabels,
  bookingTypeChoices,
  bookingChoicesWrapper,
  bookingFacilitiesDetails,
  bookingEventDetailsWrapper,
  bookingExtrasWrapper,
} from "../../css/booking.module.css";

const BookingEventDetails = () => {
  const [bookingValues, setBookingValues] = useContext(BookingContext);
  const [bookingRoom, setBookingRoom] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      bookingValues.bookingRoom && setBookingRoom(bookingValues.bookingRoom);
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingValues.bookingRoom]);

  const onSubmit = async (values) => {
    setBookingValues({ ...bookingValues, bookingDetailsEvent: values });
    navigate(`/rooms/${bookingRoom.slug}/book/event-contact`);
  };

  return (
    <div className="content-wrapper-column">
      <BookingRoomHeader />
      <BookingDateTime />
      <div className={bookingExtrasWrapper}>
        <div className={bookingEventDetailsWrapper}>
          <h2>Event details</h2>
          <div className={bookingFacilitiesDetails}>
            <Form
              onSubmit={onSubmit}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className={contactForm}>
                    <div>
                      <label htmlFor="eventName">Event name (optional)</label>
                      <span>
                        Your event name will be shown on our public booking
                        calendar
                      </span>
                      <Field name="eventName" component="input" type="text" />
                    </div>
                    <div>
                      <h3>What type of booking is this?</h3>
                      <span>
                        This will help us set the price for your booking
                      </span>
                    </div>
                  </div>
                  <div className={bookingChoicesWrapper}>
                    {bookingRoom &&
                      bookingRoom.prices.map((item, i) => (
                        <div key={i} className={bookingTypeChoices}>
                          <Field
                            name="bookingType"
                            component="input"
                            type="radio"
                            value={item.bookingType.name}
                          />
                          <div className={choicesLabels}>
                            <label> {item.bookingType.name} event</label>
                            <p>{item.bookingType.description}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className={contactForm}>
                    {values.bookingType === "Recurring" && (
                      <div>
                        <label htmlFor="eventRepeat">
                          When will the event repeat?
                        </label>
                        <span>Give us as much information as possible</span>
                        <Field
                          name="eventRepeat"
                          component="textarea"
                          rows="3"
                        />
                      </div>
                    )}

                    <div>
                      <label htmlFor="eventAbout">
                        Tell us about your event
                      </label>
                      <span>
                        We'll use this to decide whether Pannal Village Hall is
                        the right venue for you
                      </span>
                      <Field name="eventAbout" component="textarea" rows="5" />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn-green-light"
                    disabled={submitting || pristine}
                  >
                    Save and continue
                  </button>
                </form>
              )}
            />
          </div>
        </div>

        <div className={bookingInfoWrapper}>
          <BookingRoomSummary />
          <BookingRoomCost />
        </div>
      </div>
    </div>
  );
};

export default BookingEventDetails;
